import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  // 默认存储到本地local Storage里
  plugins: [createPersistedState()],
  state: {
    menuList: [
      { menuId: 1, menuPath: '/home', menuName: '首页' },
      { menuId: 2, menuPath: '/news', menuName: '新闻资讯' },
      { menuId: 3, menuPath: '/notice', menuName: '信息公告' },
      { menuId: 4, menuPath: '/profile', menuName: '公司简介' },
      { menuId: 5, menuPath: '/contcat', menuName: '联系我们' },
    ],
  },
  mutations: {
    setMenuList (state, menuList) {
      state.menuList = menuList
    },
  },
  actions: {
  },
  modules: {
  }
})
